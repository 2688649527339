<template>
  <v-dialog
    v-model="show"
    overlay-color="#000000"
    overlay-opacity="0.54"
    :max-width="isMobile ? 355 : 900"
    class="mem-dialog"
    persistent
  >
    <div class="teams-overlay" ref="teamsOverlay">
      <transition name="fade-transition" mode="out-in">
        <div class="overlay-body body-content">
          <div class="image-side">
            <img src="../../assets/teams_onboarding_step_2_icon.svg" alt="" />
            <div class="guidlines-hint" @click="viewGuidlines">
              <div>{{ $t("read_guidelines_below") }}</div>
              <i class="material-icons">keyboard_arrow_down</i>
            </div>
          </div>
          <div class="description-side" ref="guidlines">
            <div>
              <div class="mem-font--team-onboarding-modal--title side-title">
                {{ $t("onboarding_teams_step_2_title") }}
              </div>
              <p>{{ $t("onboarding_teams_step_2_p_1") }}</p>
              <p>{{ $t("onboarding_teams_step_2_p_2") }}</p>
              <p v-html="$t('onboarding_teams_step_2_p_3')"></p>
              <p v-html="$t('onboarding_teams_step_2_p_4')"></p>
              <p v-html="$t('onboarding_teams_step_2_p_5')"></p>
              <p v-html="$t('onboarding_teams_step_2_p_6')"></p>
            </div>
            <div class="overlay-control">
              <mem-button :btnType="'mem-primary'" @click="accept">{{
                $t("i_accept")
              }}</mem-button>
            </div>
          </div>
        </div>
      </transition>
      <div class="overlay-control">
        <transition name="fade-transition" mode="out-in">
          <mem-button :btnType="'mem-primary'" @click="accept">
            {{ $t("i_accept") }}
          </mem-button>
        </transition>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TeamCommunityModal",
  components: {
    "mem-button": () => import("@/components/base/BaseButton"),
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile() {
      // TODO: Find a way to avoid vuetify variable use
      return this.$vuetify.breakpoint.width < 899;
    },
  },
  methods: {
    ...mapActions(["updateOnboarding"]),
    viewGuidlines() {
      this.$refs.guidlines.scrollIntoView({ behavior: "smooth" });
    },
    async accept() {
      this.$emit("onClose");

      try {
        await this.updateOnboarding("team-onboarding");
      } catch (error) {
        console.log("Teams Guidelines onboarding update error", error);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.teams-overlay {
  background: #ffffff;
  height: 640px;
  // scroll-behavior: smooth;
  position: relative;
  border-radius: 8px;
  @media screen and (max-width: 899px) {
    height: 550px;
    overflow: hidden;
    overflow-y: auto;
  }
  .overlay-control {
    display: none;
    @media screen and (max-width: 899px) {
      display: flex;
      position: sticky;
      bottom: 24px;
      flex-direction: row;
      justify-content: center;
    }
    button {
      height: 44px;
      width: 234px;
    }
  }
  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #000000;
    z-index: 5;
    display: flex;
    @include cursorPointer;
    @media screen and (max-width: 899px) {
      color: #ffffff;
      position: sticky;
      top: 0;
      height: 0;
      flex-direction: row;
      justify-content: flex-end;
      i {
        transform: translate(-6px, 6px);
      }
    }
  }
  .overlay-body {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 434px;
    @media screen and (max-width: 899px) {
      grid-template-columns: 1fr;
      height: auto;
    }

    .image-side {
      background-color: #000000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 899px) {
        height: 292px;
        img {
          height: 166px;
          width: 166px;
        }
      }
    }
    .description-side {
      position: relative;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 20px;
      padding-left: 36px;
      padding-right: 36px;
      color: #000000;
      @media screen and (max-width: 899px) {
        padding-left: 24px;
        padding-right: 24px;
      }
      .side-title {
        margin-bottom: 28px;
        @media screen and (max-width: 899px) {
          margin-bottom: 16px;
        }
      }
      .overlay-control {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-bottom: 43px;
        button {
          height: 44px;
          width: 234px;
        }
      }
    }
  }
  .body-content {
    .image-side {
      position: relative;
      background-image: url("../../assets/teams_onboarding_step_2_bg.png");
      @media screen and (max-width: 899px) {
        background-image: url("../../assets/teams_onboarding_step_2_bg_mobile.png");
        background-size: cover;
      }
      .guidlines-hint {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translate(-50%, -50%);

        text-decoration: none;
        font-size: 12px;
        line-height: 15px;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase;

        display: none;
        flex-direction: column;
        align-items: center;

        @include cursorPointer;
        @media screen and (max-width: 899px) {
          display: flex;
        }
      }
    }
    .description-side {
      justify-content: flex-end;
      @media screen and (max-width: 899px) {
        justify-content: flex-start;
        padding-top: 28px;
        padding-bottom: 30px;
      }
      .overlay-control {
        padding-top: 58px;
        @media screen and (max-width: 899px) {
          display: none;
        }
      }
    }
  }
}
</style>
